<template>
    <div class="store-locator__content">
        <div class="store-locator__panel">
            <div class="store-locator__filter">
                <div class="umbraco-forms-container">
                    <div class="umbraco-forms-field dropdown">
                        <label for="storeSelectedCountry" class="umbraco-forms-label">{{ $t('Dictionary.StoreLocator.Country') }} *</label>
                        <div class="umbraco-forms-field-wrapper">
                            <select id="storeSelectedCountry" v-model="selectedCountry" name="storeSelectedCountry" @change="countryUpdated">
                                <option value="">
                                    {{ $t('Dictionary.StoreLocator.CountryPlaceholder') }}
                                </option>
                                <option value="AT">
                                    Austria
                                </option>
                                <option value="BE">
                                    Belgium
                                </option>
                                <option value="BA">
                                    Bosnia and Herzegovina
                                </option>
                                <option value="BG">
                                    Bulgaria
                                </option>
                                <option value="HR">
                                    Croatia
                                </option>
                                <option value="CZ">
                                    Czech Republic
                                </option>
                                <option value="DK">
                                    Denmark
                                </option>
                                <option value="EE">
                                    Estonia
                                </option>
                                <option value="FO">
                                    Faroe Islands
                                </option>
                                <option value="FI">
                                    Finland
                                </option>
                                <option value="FR">
                                    France
                                </option>
                                <option value="DE">
                                    Germany
                                </option>
                                <option value="HU">
                                    Hungary
                                </option>
                                <option value="IE">
                                    Ireland
                                </option>
                                <option value="IT">
                                    Italy
                                </option>
                                <option value="JP">
                                    Japan
                                </option>
                                <option value="KW">
                                    Kuwait
                                </option>
                                <option value="LV">
                                    Latvia
                                </option>
                                <option value="LT">
                                    Lithuania
                                </option>
                                <option value="LU">
                                    Luxembourg
                                </option>
                                <option value="NL">
                                    Netherlands
                                </option>
                                <option value="NO">
                                    Norway
                                </option>
                                <option value="PL">
                                    Poland
                                </option>
                                <option value="PT">
                                    Portugal
                                </option>
                                <option value="RO">
                                    Romania
                                </option>
                                <option value="RS">
                                    Serbia
                                </option>
                                <option value="SK">
                                    Slovakia
                                </option>
                                <option value="SI">
                                    Slovenia
                                </option>
                                <option value="ES">
                                    Spain
                                </option>
                                <option value="SE">
                                    Sweden
                                </option>
                                <option value="CH">
                                    Switzerland
                                </option>
                                <option value="TR">
                                    Turkey
                                </option>
                                <option value="GB">
                                    United Kingdom
                                </option>
                                <option value="US">
                                    United States
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="umbraco-forms-field">
                        <label for="storeSearchTerm" class="umbraco-forms-label">{{ $t('Dictionary.StoreLocator.Search') }}</label>
                        <div class="umbraco-forms-field-wrapper">
                            <input id="storeSearchTerm" type="text" name="storeSearchTerm" class="text" :placeholder="$t('Dictionary.StoreLocator.SearchPlaceholder')" @input="searchTermUpdated($event.target.value)">
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="filteredStores && filteredStores.length" class="store-locator__list">
                <div class="store-locator__list-inner">
                    <div v-for="(store, index) in filteredStores" :key="index" class="store-locator__item">
                        <strong class="store-locator__item-title">{{ store.shopName }}</strong>
                        <span class="store-locator__item-address">{{ formatStoreAddress(store) }}</span>
                        <a :href="googleMapsUrl(store)" target="_blank" :title="$t('Dictionary.StoreLocator.GetDirections')" class="store-locator__item-link">{{ $t('Dictionary.StoreLocator.GetDirections') }}</a>
                    </div>
                </div>
            </div>
            <div v-else-if="!isLoading && !errorMessage" class="store-locator__no-results">
                <template v-if="selectedCountry">
                    {{ $t('Dictionary.StoreLocator.NoResults') }}
                </template>
                <template v-else-if="searchTerm">
                    {{ $t('Dictionary.StoreLocator.CountryRequired') }}
                </template>
            </div>
            <div v-if="errorMessage" class="store-locator__error">
                {{ errorMessage }}
            </div>
        </div>
        <div class="store-locator__map">
            <GoogleMap class-name="store-locator__map-inner" :api-key="apiKey" :map-id="mapId" :markers="filteredStores" />
            <div class="spinner-wrap spinner-wrap--absolute" :aria-hidden="isLoading ? 'false' : 'true'">
                <div class="spinner"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStores } from '../composables/useStores';
import apiService from '../../api';
import GoogleMap from '../components/google-map.vue';

export default {
    name: 'StoreLocator',
    components: {
        GoogleMap
    },
    props: {
        apiKey: {
            type: String,
            required: true
        },
        mapId: {
            type: String,
            required: true
        }
    },
    setup() {
        const { formatStoreAddress, googleMapsUrl } = useStores();

        // Return the methods to make them available in the template
        return {
            formatStoreAddress,
            googleMapsUrl
        };
    },
    data() {
        return {
            isLoading: false,
            selectedCountry: '',
            searchTerm: '',
            debounceTimer: null,
            stores: [],
            errorMessage: ''
        };
    },
    computed: {
        filteredStores() {
            if (!this.isLoading) {
                const searchTermLower = this.searchTerm && this.searchTerm.toLowerCase();
                return this.stores
                    .filter(store => (
                        store.shopName.toLowerCase().includes(searchTermLower) ||
                        store.shopAddress.toLowerCase().includes(searchTermLower) ||
                        store.shopAddress2.toLowerCase().includes(searchTermLower) ||
                        store.shopPostcode.toLowerCase().includes(searchTermLower) ||
                        store.shopCity.toLowerCase().includes(searchTermLower)
                    ))
                    .sort((a, b) => a.shopName.localeCompare(b.shopName))
                    .map(store => ({
                        ...store,
                        position: { lat: store.latitude, lng: store.longitude }
                    }));
            }
            return [];
        }
    },
    created() {
        this.getStores();
    },
    methods: {
        countryUpdated() {
            this.getStores();
        },
        searchTermUpdated(value) {
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => {
                this.searchTerm = value;
            }, 300);
        },
        getStores() {
            const vm = this;

            vm.errorMessage = '';

            if (this.selectedCountry) {
                vm.isLoading = true;

                apiService.store.getStores(this.selectedCountry)
                    .then(response => {
                        vm.stores = response.data;
                    })
                    .catch(errors => {
                        const errorResponse = errors.response.data.errorMessage ? errors.response.data.errorMessage : `Error (status code ${errors.response.status})`;
                        vm.errorMessage = errorResponse;
                    })
                    .then(function () {
                        vm.isLoading = false;
                    });
            } else {
                vm.stores = [];
            }
        }
    }
};
</script>
