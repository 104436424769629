export function useStores() {

    function formatStoreAddress(store) {
        let address = store.shopAddress;
        if (store.shopAddress2) {
            address += `, ${store.shopAddress2}`;
        }
        address += `, ${store.shopPostcode} ${store.shopCity}`;
        return address;
    }

    function googleMapsUrl(store) {
        const addressParts = [
            store.shopAddress,
            store.shopAddress2,
            store.shopPostcode,
            store.shopCity,
            store.shopCountry
        ].filter(part => part);

        const destination = addressParts.join(', ');
        const encodedDestination = encodeURIComponent(destination);

        return `https://www.google.com/maps/dir/?api=1&destination=${encodedDestination}`;
    }

    return {
        formatStoreAddress,
        googleMapsUrl
    };
}
