import axios from 'axios';

// const culture = document.documentElement.lang;

const apiService = {
    store: {
        getStores(country) {
            return axios.get('/Umbraco/Api/StoreApi/GetStores', { params: { country } }).then(response => response);
        }
    }
};

export default apiService;
