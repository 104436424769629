export function setupIntersect() {
    const intersectElements = document.querySelectorAll('[data-intersect]');

    const config = {
        root: null,
        rootMargin: '-25%',
        threshold: [0, 0.2, 1]
    };

    const observer = new IntersectionObserver(onChange, config);

    function onChange(changes) {
        changes.forEach(change => {
            if (change.intersectionRatio > 0) {
                change.target.classList.add('intersected');
            }
        });
    }

    Array.from(intersectElements).forEach(intersectElement => {
        observer.observe(intersectElement);
    });
}
